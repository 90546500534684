.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.cone {
    margin: 1vw;
    width: 3vw;
    height: 3vw;
    border-radius: 100%;
    transition: all 1s;
    background-color: #DCDCDC;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}